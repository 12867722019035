<template>
    <div style="height: 100%">

        <CRow>
            <CCol lg="3">
                <CInput
                        type="text"
                        name="filter"
                        placeholder="Поиск"
                        v-model="conditions.filter"
                        @input="filterNames"
                />
            </CCol>
            <CCol lg="3">
                <CInput
                    type="date"
                    name="action"
                    v-model="conditions.created_date"
                    required
                    @input="getResults"
                />
            </CCol>
            <CCol lg="3">
                <form @submit.prevent="orderReport">
                    <CButton
                            type="submit"
                            color="primary"
                            :disabled="reportOrdered">{{orderReportText}}</CButton>
                    <CInput
                        type="hidden"
                        name="date"
                        v-model="conditions.created_date"
                    />
                </form>
            </CCol>
        </CRow>

        <c-grid
                ref="grid"
                :data="tData"
                :frozen-col-count="1"
                @changed-value="$refs.grid.invalidate()"
                :theme="userTheme"
                :filter="filterData">
            <!-- define checkbox -->
            <c-grid-column
                    field="name"
                    width="20%"
                    min-width="150"
                    caption="Нитка"
            />
            <!-- multiple header -->
            <c-grid-column-group
                    v-for="(elem, index_g) in times_dataTypes" v-bind:key="index_g"
                    :field="elem.name"
                    :caption="elem.name"
                    :header-style="{textAlign:'center'}">

                <c-grid-input-column
                    v-for="(elem_1, index) in elem.types" v-bind:key="index"
                    :field="elem_1.id + '_' + elem.id"
                    :caption="elem_1.name"
                    :column-style="{textAlign: 'center'}"
                />
            </c-grid-column-group>
        </c-grid>
    </div>
</template>

<script>
    import * as cGridAll from 'vue-cheetah-grid';
    import Vue from 'vue';
    import axios from "axios";
    import store from "../../store";

    const materialDesignTheme = cGridAll.cheetahGrid.themes.MATERIAL_DESIGN;

  Vue.use(materialDesignTheme)

    let tData = [];
    let sData = [];

  const formatYmd = (date) => {
      return date.toISOString().slice(0, 10)
  };

  let tzoffset = (new Date()).getTimezoneOffset() * 60000;
  let today = formatYmd(new Date(Date.now() - tzoffset));

export default {
    name: 'Monitor',
    data: function () {
      return {
        orderReportText: "Скачать отчет",
        reportOrdered: false,
        tData,
        conditions:
          {
              filter: "",
              created_date: today
          },
        temp_tdata: [],
        colData: [],
        colTimes: [],
        time_ids: "",
        times_dataTypes: [
          {
            "name": "01:00",
            "id": "28",
            "types": [
              {
                "0": "5",
                "1": "PВх",
                "2": "p_in",
                "id": "5",
                "name": "PВх",
                "label": "p_in",
                "time_id": "28"
              },
              {
                "0": "6",
                "1": "PВых",
                "2": "p_out",
                "id": "6",
                "name": "PВых",
                "label": "p_out",
                "time_id": "28"
              },
                {
                    "0": "9",
                    "1": "Qч",
                    "2": "q_hour",
                    "id": "9",
                    "name": "Qч",
                    "label": "q_hour",
                    "time_id": "28"
                },
              {
                "0": "7",
                "1": "Т",
                "2": "temper",
                "id": "7",
                "name": "Т",
                "label": "temper",
                "time_id": "28"
              },
              {
                "0": "8",
                "1": "∆P",
                "2": "diff",
                "id": "8",
                "name": "∆P",
                "label": "diff",
                "time_id": "28"
              }
            ]
          },
          {
            "name": "03:00",
            "id": "29",
            "types": [
              {
                "0": "5",
                "1": "PВх",
                "2": "p_in",
                "id": "5",
                "name": "PВх",
                "label": "p_in",
                "time_id": "29"
              },
              {
                "0": "6",
                "1": "PВых",
                "2": "p_out",
                "id": "6",
                "name": "PВых",
                "label": "p_out",
                "time_id": "29"
              },
              {
                "0": "7",
                "1": "Темп",
                "2": "temper",
                "id": "7",
                "name": "Темп",
                "label": "temper",
                "time_id": "29"
              },
              {
                "0": "8",
                "1": "Перепад",
                "2": "diff",
                "id": "8",
                "name": "Перепад",
                "label": "diff",
                "time_id": "29"
              },
              {
                "0": "9",
                "1": "Q Час",
                "2": "q_hour",
                "id": "9",
                "name": "Q Час",
                "label": "q_hour",
                "time_id": "29"
              }
            ]
          },
          {
            "name": "05:00",
            "id": "30",
            "types": [
              {
                "0": "5",
                "1": "PВх",
                "2": "p_in",
                "id": "5",
                "name": "PВх",
                "label": "p_in",
                "time_id": "30"
              },
              {
                "0": "6",
                "1": "PВых",
                "2": "p_out",
                "id": "6",
                "name": "PВых",
                "label": "p_out",
                "time_id": "30"
              },
              {
                "0": "7",
                "1": "Темп",
                "2": "temper",
                "id": "7",
                "name": "Темп",
                "label": "temper",
                "time_id": "30"
              },
              {
                "0": "8",
                "1": "Перепад",
                "2": "diff",
                "id": "8",
                "name": "Перепад",
                "label": "diff",
                "time_id": "30"
              },
              {
                "0": "9",
                "1": "Q Час",
                "2": "q_hour",
                "id": "9",
                "name": "Q Час",
                "label": "q_hour",
                "time_id": "30"
              }
            ]
          },
          {
            "name": "07:00",
            "id": "31",
            "types": [
              {
                "0": "5",
                "1": "PВх",
                "2": "p_in",
                "id": "5",
                "name": "PВх",
                "label": "p_in",
                "time_id": "31"
              },
              {
                "0": "6",
                "1": "PВых",
                "2": "p_out",
                "id": "6",
                "name": "PВых",
                "label": "p_out",
                "time_id": "31"
              },
              {
                "0": "7",
                "1": "Темп",
                "2": "temper",
                "id": "7",
                "name": "Темп",
                "label": "temper",
                "time_id": "31"
              },
              {
                "0": "8",
                "1": "Перепад",
                "2": "diff",
                "id": "8",
                "name": "Перепад",
                "label": "diff",
                "time_id": "31"
              },
              {
                "0": "9",
                "1": "Q Час",
                "2": "q_hour",
                "id": "9",
                "name": "Q Час",
                "label": "q_hour",
                "time_id": "31"
              }
            ]
          },
          {
            "name": "09:00",
            "id": "32",
            "types": [
              {
                "0": "5",
                "1": "PВх",
                "2": "p_in",
                "id": "5",
                "name": "PВх",
                "label": "p_in",
                "time_id": "32"
              },
              {
                "0": "6",
                "1": "PВых",
                "2": "p_out",
                "id": "6",
                "name": "PВых",
                "label": "p_out",
                "time_id": "32"
              },
              {
                "0": "7",
                "1": "Темп",
                "2": "temper",
                "id": "7",
                "name": "Темп",
                "label": "temper",
                "time_id": "32"
              },
              {
                "0": "8",
                "1": "Перепад",
                "2": "diff",
                "id": "8",
                "name": "Перепад",
                "label": "diff",
                "time_id": "32"
              },
              {
                "0": "9",
                "1": "Q Час",
                "2": "q_hour",
                "id": "9",
                "name": "Q Час",
                "label": "q_hour",
                "time_id": "32"
              }
            ]
          },
          {
            "name": "11:00",
            "id": "33",
            "types": [
              {
                "0": "5",
                "1": "PВх",
                "2": "p_in",
                "id": "5",
                "name": "PВх",
                "label": "p_in",
                "time_id": "33"
              },
              {
                "0": "6",
                "1": "PВых",
                "2": "p_out",
                "id": "6",
                "name": "PВых",
                "label": "p_out",
                "time_id": "33"
              },
              {
                "0": "7",
                "1": "Темп",
                "2": "temper",
                "id": "7",
                "name": "Темп",
                "label": "temper",
                "time_id": "33"
              },
              {
                "0": "8",
                "1": "Перепад",
                "2": "diff",
                "id": "8",
                "name": "Перепад",
                "label": "diff",
                "time_id": "33"
              },
              {
                "0": "9",
                "1": "Q Час",
                "2": "q_hour",
                "id": "9",
                "name": "Q Час",
                "label": "q_hour",
                "time_id": "33"
              }
            ]
          },
          {
            "name": "13:00",
            "id": "34",
            "types": [
              {
                "0": "5",
                "1": "PВх",
                "2": "p_in",
                "id": "5",
                "name": "PВх",
                "label": "p_in",
                "time_id": "34"
              },
              {
                "0": "6",
                "1": "PВых",
                "2": "p_out",
                "id": "6",
                "name": "PВых",
                "label": "p_out",
                "time_id": "34"
              },
              {
                "0": "7",
                "1": "Темп",
                "2": "temper",
                "id": "7",
                "name": "Темп",
                "label": "temper",
                "time_id": "34"
              },
              {
                "0": "8",
                "1": "Перепад",
                "2": "diff",
                "id": "8",
                "name": "Перепад",
                "label": "diff",
                "time_id": "34"
              },
              {
                "0": "9",
                "1": "Q Час",
                "2": "q_hour",
                "id": "9",
                "name": "Q Час",
                "label": "q_hour",
                "time_id": "34"
              }
            ]
          },
          {
            "name": "15:00",
            "id": "35",
            "types": [
              {
                "0": "5",
                "1": "PВх",
                "2": "p_in",
                "id": "5",
                "name": "PВх",
                "label": "p_in",
                "time_id": "35"
              },
              {
                "0": "6",
                "1": "PВых",
                "2": "p_out",
                "id": "6",
                "name": "PВых",
                "label": "p_out",
                "time_id": "35"
              },
              {
                "0": "7",
                "1": "Темп",
                "2": "temper",
                "id": "7",
                "name": "Темп",
                "label": "temper",
                "time_id": "35"
              },
              {
                "0": "8",
                "1": "Перепад",
                "2": "diff",
                "id": "8",
                "name": "Перепад",
                "label": "diff",
                "time_id": "35"
              },
              {
                "0": "9",
                "1": "Q Час",
                "2": "q_hour",
                "id": "9",
                "name": "Q Час",
                "label": "q_hour",
                "time_id": "35"
              }
            ]
          },
          {
            "name": "17:00",
            "id": "36",
            "types": [
              {
                "0": "5",
                "1": "PВх",
                "2": "p_in",
                "id": "5",
                "name": "PВх",
                "label": "p_in",
                "time_id": "36"
              },
              {
                "0": "6",
                "1": "PВых",
                "2": "p_out",
                "id": "6",
                "name": "PВых",
                "label": "p_out",
                "time_id": "36"
              },
              {
                "0": "7",
                "1": "Темп",
                "2": "temper",
                "id": "7",
                "name": "Темп",
                "label": "temper",
                "time_id": "36"
              },
              {
                "0": "8",
                "1": "Перепад",
                "2": "diff",
                "id": "8",
                "name": "Перепад",
                "label": "diff",
                "time_id": "36"
              },
              {
                "0": "9",
                "1": "Q Час",
                "2": "q_hour",
                "id": "9",
                "name": "Q Час",
                "label": "q_hour",
                "time_id": "36"
              }
            ]
          },
          {
            "name": "19:00",
            "id": "37",
            "types": [
              {
                "0": "5",
                "1": "PВх",
                "2": "p_in",
                "id": "5",
                "name": "PВх",
                "label": "p_in",
                "time_id": "37"
              },
              {
                "0": "6",
                "1": "PВых",
                "2": "p_out",
                "id": "6",
                "name": "PВых",
                "label": "p_out",
                "time_id": "37"
              },
              {
                "0": "7",
                "1": "Темп",
                "2": "temper",
                "id": "7",
                "name": "Темп",
                "label": "temper",
                "time_id": "37"
              },
              {
                "0": "8",
                "1": "Перепад",
                "2": "diff",
                "id": "8",
                "name": "Перепад",
                "label": "diff",
                "time_id": "37"
              },
              {
                "0": "9",
                "1": "Q Час",
                "2": "q_hour",
                "id": "9",
                "name": "Q Час",
                "label": "q_hour",
                "time_id": "37"
              }
            ]
          },
          {
            "name": "21:00",
            "id": "38",
            "types": [
              {
                "0": "5",
                "1": "PВх",
                "2": "p_in",
                "id": "5",
                "name": "PВх",
                "label": "p_in",
                "time_id": "38"
              },
              {
                "0": "6",
                "1": "PВых",
                "2": "p_out",
                "id": "6",
                "name": "PВых",
                "label": "p_out",
                "time_id": "38"
              },
              {
                "0": "7",
                "1": "Темп",
                "2": "temper",
                "id": "7",
                "name": "Темп",
                "label": "temper",
                "time_id": "38"
              },
              {
                "0": "8",
                "1": "Перепад",
                "2": "diff",
                "id": "8",
                "name": "Перепад",
                "label": "diff",
                "time_id": "38"
              },
              {
                "0": "9",
                "1": "Q Час",
                "2": "q_hour",
                "id": "9",
                "name": "Q Час",
                "label": "q_hour",
                "time_id": "38"
              }
            ]
          },
          {
            "name": "22:00",
            "id": "39",
            "types": [
              {
                "0": "9",
                "1": "Q Час",
                "2": "q_hour",
                "id": "9",
                "name": "Q Час",
                "label": "q_hour",
                "time_id": "39"
              },
              {
                "0": "10",
                "1": "Q Накопленный",
                "2": "q_accum",
                "id": "10",
                "name": "Q Накопленный",
                "label": "q_accum",
                "time_id": "39"
              }
            ]
          },
          {
            "name": "23:00",
            "id": "42",
            "types": [
              {
                "0": "5",
                "1": "PВх",
                "2": "p_in",
                "id": "5",
                "name": "PВх",
                "label": "p_in",
                "time_id": "42"
              },
              {
                "0": "6",
                "1": "PВых",
                "2": "p_out",
                "id": "6",
                "name": "PВых",
                "label": "p_out",
                "time_id": "42"
              },
              {
                "0": "7",
                "1": "Темп",
                "2": "temper",
                "id": "7",
                "name": "Темп",
                "label": "temper",
                "time_id": "42"
              },
              {
                "0": "8",
                "1": "Перепад",
                "2": "diff",
                "id": "8",
                "name": "Перепад",
                "label": "diff",
                "time_id": "42"
              },
              {
                "0": "9",
                "1": "Q Час",
                "2": "q_hour",
                "id": "9",
                "name": "Q Час",
                "label": "q_hour",
                "time_id": "42"
              }
            ]
          },
          {
            "name": "Суточный",
            "id": "43",
            "types": [
              {
                "0": "11",
                "1": "Q Суточный",
                "2": "q_day",
                "id": "11",
                "name": "Q Суточный",
                "label": "q_day",
                "time_id": "43"
              }
            ]
          }
        ],
        userTheme: materialDesignTheme.extends({
          defaultBgColor({col, row, grid}) {
              const record = tData[row - grid.frozenRowCount];
              if (row < grid.frozenRowCount) {
                return null;
              }
              if (record !== undefined && record.is_region !== undefined) {
                return "#3c4b64"
              }
              if (record !== undefined && record.name === "Итого") {
                return '#DEF';
              } else {
                if (col !== 0 && (Math.floor((col-1)/6) + 1) % 2 === 0) {
                  return "#f1f1f1"
                }
              }
            },
          color({col, row, grid}) {
            const record = tData[row - grid.frozenRowCount];
            if (row < grid.frozenRowCount) {
              return null;
            }
            if (record !== undefined && record.is_region !== undefined) {
              return "#fff"
            }
          },
          borderColor({col, row, grid}) {
            const record = tData[row - grid.frozenRowCount];
            if (row < grid.frozenRowCount) {
              return null;
            }
            if (record !== undefined && record.is_region !== undefined) {
              return "#3c4b64"
            }
            return "#ccc"
          }
        }),
        form: {
          action: 'create_data',
          operator_id: "13",
          datas: [],
          time_id: "",
          line_id: "",
          created_date: "",
        },
      }
    },
    mounted: function () {
        this.getResults();
        this.$refs.grid.$on("changed-value",(e)=> {
            this.createData(e)
        })
    },
    methods: {
        createData(in_data) {

            if (parseFloat(store.getters.userRole) < 3) {
                console.log("Недостаточно прав")
            } else {

                let bodyFormData = new FormData();

                bodyFormData.append('action', "create_data_from_table");
                bodyFormData.append('operator_id', this.form.operator_id);
                bodyFormData.append('line_id', in_data.record.id);
                bodyFormData.append('time_id', in_data.field.split("_")[1]);
                bodyFormData.append('data_type_id', in_data.field.split("_")[0]);
                bodyFormData.append('s_value', in_data.value);

                axios({
                    method: "post",
                    url: `${axios.defaults.baseURL}`,
                    data: bodyFormData
                })
                .then((r) => {
                    console.log(r)
                })
                .catch((error) => {
                    console.error(error)
                }).finally(() => {});
            }
        },
        getResults() {
            let startDate = this.conditions.created_date;
            this.apiDatas = [];
            this.time_ids = "";

            axios
                .get(`${axios.defaults.baseURL}?action=get_times`)
                .then((response) => {
                    response.data.forEach((el, i) => {
                        if (el.id !== "39") {
                            this.colTimes.push({
                                name: el.name,
                                time_id: el.id
                            });
                            this.time_ids = this.time_ids + el.id + (i < response.data.length - 1 ? "," : "")
                        }
                    })
                    axios
                        .get(`${axios.defaults.baseURL}?action=get_data_bulk_named&times=${this.time_ids}`)
                        .then((response1) => {
                            this.times_dataTypes = JSON.parse(JSON.stringify(response1.data))
                        })
                    })

            axios
                .get(`${axios.defaults.baseURL}?action=get_all_lines_w_region`)
                .then((response) => {
                    this.temp_tdata = response.data;

                    // Закидываем все нитки в тДата
                    this.tData = Object.values(this.temp_tdata);

                    // запрашиваем отправленные данные на указанную дату
                    axios
                            .get(`${axios.defaults.baseURL}?action=get_results_by_date&created_date=${startDate}`)
                            .then((results_response) => {

                                let regions = [];
                                let region_id;

                                // для подсчёта по Q час (id типа данных = 9)
                                // создаём массив чтобы складывать в него данные, выбранные по региону, типу данных и режиму
                                // тип данных всегда = 9
                                // 9_(time_id)_(region_id)

                                let temp_count_array = {};
                                let temp_count_array_10 = {};
                                let total_count = {};
                                let temp_time = "";

                                // бежим по отправленным данным

                                Object.keys(results_response.data).forEach((el) => {


                                    // получаем "адрес" целевой ячейки внутри строки
                                    let a_c;
                                    // получаем значение для целевой ячейки
                                    let b;
                                    // создаём и заполняем объект {адрес_ячейки : значение_ячейки}
                                    let f = {};

                                    let r_id;
                                    // получаем "адрес" целевой ячейки внутри строки
                                    a_c = results_response.data[el].target;
                                    // получаем значение для целевой ячейки
                                    b = results_response.data[el].datavalue;
                                    // создаём и заполняем объект {адрес_ячейки : значение_ячейки}
                                    f[a_c] = b;

                                    // нацеливаемся на нужную строку и добавляем информацию для найденной ячейки

                                    this.temp_tdata[el.split("-")[0] + "_id"] = Object.assign({},this.temp_tdata[el.split("-")[0] + "_id"], f);

                                    if (temp_time === "") {
                                        temp_time = results_response.data[el].time_id;
                                    }
                                    temp_time = results_response.data[el].time_id;

                                    if (results_response.data[el].is_countable !== "1") {
                                        region_id = results_response.data[el].region_id;
                                    }

                                    r_id = results_response.data[el].region_id + "_" + temp_time;
                                    temp_count_array[r_id] = temp_count_array[r_id] !== undefined ? temp_count_array[r_id] : [];
                                    temp_count_array_10[r_id] = temp_count_array_10[r_id] !== undefined ? temp_count_array_10[r_id] : [];
                                    total_count[r_id] = total_count[r_id] !== undefined ? total_count[r_id] : [];

                                    if (temp_time === results_response.data[el].time_id && results_response.data[el].datavalue !== "" && results_response.data[el].datavalue !== "0"  && results_response.data[el].datavalue !== "00") {
                                        if (results_response.data[el].data_type_id === "9") {
                                            temp_count_array[r_id].push(parseInt(results_response.data[el].datavalue))
                                        } else if (results_response.data[el].data_type_id === "10") {
                                            temp_count_array_10[r_id].push(parseInt(results_response.data[el].datavalue))
                                        } else if (results_response.data[el].data_type_id === "11") {
                                            total_count[r_id].push(parseInt(results_response.data[el].datavalue))
                                        }
                                    }

                                    if (!regions.includes(region_id)) {
                                        regions.push(region_id)
                                    }

                                })

                                let r_total = "total_r_count";
                                let temp_c_arr_reduce;
                                let temp_total_arr_reduce;
                                let temp_c_arr_reduce_10;
                                let all_total_c = 0;
                                let all_total = {};

                                this.colTimes.forEach((t_id) => {
                                    regions.forEach((r_id) => {


                                        if (temp_count_array[r_id+"_"+t_id.time_id] !== undefined && temp_count_array[r_id+"_"+t_id.time_id].length > 0) {
                                            temp_c_arr_reduce = temp_count_array[r_id + "_" + t_id.time_id].reduce((a,b)=>a+b,0)
                                        } else {
                                            temp_c_arr_reduce = "0"
                                        }
                                        if (temp_count_array_10[r_id+"_"+t_id.time_id] !== undefined && temp_count_array_10[r_id+"_"+t_id.time_id].length > 0) {
                                            temp_c_arr_reduce_10 = temp_count_array_10[r_id + "_" + t_id.time_id].reduce((a,b)=>a+b,0)
                                        } else {
                                            temp_c_arr_reduce_10 = "0"
                                        }
                                        if (total_count[r_id+"_"+t_id.time_id] !== undefined && total_count[r_id+"_"+t_id.time_id].length > 0) {
                                            temp_total_arr_reduce = total_count[r_id+"_"+t_id.time_id].reduce((a,b)=>a+b,0)
                                        } else {
                                            temp_total_arr_reduce = "0"
                                        }

                                        let count_target;
                                        let count_target_10;

                                        if (t_id.time_id === "43") {
                                            count_target = "11_" + t_id.time_id

                                            let total_target_obj = {};

                                            total_target_obj[count_target] = temp_total_arr_reduce;

                                            Object.assign(this.temp_tdata[r_id + "_id_r_count"], total_target_obj)
                                            all_total_c = all_total_c + parseFloat(temp_total_arr_reduce);
                                        } else {
                                            count_target = "9_" + t_id.time_id
                                            count_target_10 = "10_" + t_id.time_id

                                            let count_target_obj = {};
                                            count_target_obj[count_target] = temp_c_arr_reduce;

                                            let count_target_obj_10 = {};
                                            count_target_obj_10[count_target_10] = temp_c_arr_reduce_10;

                                            Object.assign(this.temp_tdata[r_id + "_id_r_count"], count_target_obj)
                                            Object.assign(this.temp_tdata[r_id + "_id_r_count"], count_target_obj_10)
                                            all_total_c = all_total_c + parseFloat(temp_c_arr_reduce);
                                        }
                                    })

                                    let count_target;

                                    if (all_total_c === 0) {
                                        all_total_c = "0"
                                    }

                                    if (t_id.time_id === "43") {
                                        count_target = "11_" + t_id.time_id;

                                        all_total[count_target] = all_total_c;

                                        Object.assign(this.temp_tdata[r_total], all_total)
                                    } else {
                                        count_target = "9_" + t_id.time_id;

                                        all_total[count_target] = all_total_c;

                                        Object.assign(this.temp_tdata[r_total], all_total)
                                    }

                                    all_total_c = 0;

                                })
                                tData = Object.values(this.temp_tdata);
                                this.tData = tData;
                                this.$refs.grid.invalidate()

                            })

                })
        },
        filterData(e) {
            if (e.name!== undefined) {
                if (e.name.toLowerCase().indexOf(this.conditions.filter.toLowerCase()) >= 0) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        filterNames() {

            sData = tData.filter((e1) => {
                if (e1.name!== undefined) {
                    if (e1.name.toLowerCase().includes(this.conditions.filter.toLowerCase()) >= 0) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            })

            this.tData = sData;

            this.$refs.grid.invalidate()

        },
        orderReport() {
          this.reportOrdered = true;


          let bodyFormData = new FormData();

          bodyFormData.append('date', this.conditions.created_date);
          bodyFormData.append('userid', store.getters.StateUser);

          axios({
            method: "post",
            url: `https://report.kalish.uz/report.php`,
            data: bodyFormData,
            headers : {
              // withCredentials: true
            },
            responseType: 'blob',
          })
            .then((r) => {
              // console.log(r)
              const url = window.URL.createObjectURL(new Blob([r.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `Отчет-${this.conditions.created_date}.xlsx`); //or any other extension
              document.body.appendChild(link);
              link.click();
              this.reportOrdered = false;
            })
            .catch((error) => {
              if (error.response.status!== null && error.response.status === 429) {
                this.orderReportText = "Превышен лимит";
              }
              console.error(error)
            }).finally(() => {});
        }
    },

    components: {
      ...cGridAll
    }

  }
</script>


<style>
    .container-fluid {
        height: 100%;
    }
</style>
